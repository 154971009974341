/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Decimal: any;
  GenericScalar: any;
  JSONString: any;
};

/** An enumeration. */
export enum ApiKeyType {
  CreatorServiceAccount = 'CREATOR_SERVICE_ACCOUNT',
  ExportMetricsAllowedModelsServiceAccount = 'EXPORT_METRICS_ALLOWED_MODELS_SERVICE_ACCOUNT',
  ExportMetricsAllModelsServiceAccount = 'EXPORT_METRICS_ALL_MODELS_SERVICE_ACCOUNT',
  InvokeAllowedModelsServiceAccount = 'INVOKE_ALLOWED_MODELS_SERVICE_ACCOUNT',
  InvokeAllModelsServiceAccount = 'INVOKE_ALL_MODELS_SERVICE_ACCOUNT',
  Personal = 'PERSONAL'
}

export type ActivateChainDeployment = {
  __typename?: 'ActivateChainDeployment';
  ok: Maybe<Scalars['Boolean']>;
  primary_chain_deployment: Maybe<ChainDeploymentType>;
};

export type ActivateModelVersion = {
  __typename?: 'ActivateModelVersion';
  ok: Maybe<Scalars['Boolean']>;
  primary_version: Maybe<ModelVersionType>;
};

export type AddSharedState = {
  __typename?: 'AddSharedState';
  id: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum ApprovalStatus {
  Approved = 'APPROVED',
  Default = 'DEFAULT',
  EligibleToJoin = 'ELIGIBLE_TO_JOIN',
  Pending = 'PENDING',
  PendingOrgApproval = 'PENDING_ORG_APPROVAL',
  Rejected = 'REJECTED'
}

export type AtomSignature = {
  __typename?: 'AtomSignature';
  args: Maybe<Array<Maybe<CallableParam>>>;
  return_annotation: Maybe<Scalars['String']>;
};

export type AutoscalingSettingsType = {
  idle_time_seconds?: InputMaybe<Scalars['Int']>;
  max_replica?: InputMaybe<Scalars['Int']>;
  min_replica?: InputMaybe<Scalars['Int']>;
  parallelism?: InputMaybe<Scalars['Int']>;
  scaling_window_seconds?: InputMaybe<Scalars['Int']>;
};

export type AvailableGitRepositoryToImport = {
  __typename?: 'AvailableGitRepositoryToImport';
  description: Maybe<Scalars['String']>;
  full_name: Maybe<Scalars['String']>;
  size_kb: Maybe<Scalars['Int']>;
  too_large_to_import: Maybe<Scalars['Boolean']>;
};

/** Billing information for a single entity (ie: an OracleVersion) */
export type BillItem = {
  __typename?: 'BillItem';
  cost: Maybe<Scalars['Decimal']>;
  entity: Maybe<Entity>;
  inference_requests: Maybe<Scalars['Int']>;
  minutes: Maybe<Scalars['Int']>;
  usage_per_day: Maybe<Array<Maybe<UsagePerDay>>>;
};

export type BillfredAction = HijackAction | NavigateAction;

export type BillfredDetailedEntity = {
  __typename?: 'BillfredDetailedEntity';
  actions: Maybe<Array<Maybe<BillfredAction>>>;
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  related_entities: Maybe<Array<Maybe<EntityRelation>>>;
  second_subtitle_field_name: Maybe<Scalars['String']>;
  second_subtitle_field_value: Maybe<Scalars['String']>;
  subtitle_field_name: Maybe<Scalars['String']>;
  subtitle_field_value: Maybe<Scalars['String']>;
};

export type BillfredEntity = {
  __typename?: 'BillfredEntity';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

export type BillfredEntityType = {
  __typename?: 'BillfredEntityType';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  plural: Maybe<Scalars['String']>;
  shortcut: Maybe<Scalars['String']>;
};

export type BillingPeriod = {
  __typename?: 'BillingPeriod';
  end: Maybe<Scalars['DateTime']>;
  index: Maybe<Scalars['Int']>;
  is_current: Maybe<Scalars['Boolean']>;
  start: Maybe<Scalars['DateTime']>;
};

export type CallableParam = {
  __typename?: 'CallableParam';
  annotation: Maybe<Scalars['String']>;
  default: Maybe<Scalars['String']>;
  kind: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

export type CancelModelDeployment = {
  __typename?: 'CancelModelDeployment';
  cancelled: Maybe<Scalars['Boolean']>;
  model_version: Maybe<ModelVersionType>;
};

export type CancelModelVersionPromotion = {
  __typename?: 'CancelModelVersionPromotion';
  canceled: Maybe<Scalars['Boolean']>;
  model_version: Maybe<ModelVersionType>;
};

export type CeleryCrontabScheduleType = {
  __typename?: 'CeleryCrontabScheduleType';
  /** Cron Days Of The Month to Run. Use "*" for "all". (Example: "1,15") */
  day_of_month: Scalars['String'];
  /** Cron Days Of The Week to Run. Use "*" for "all", Sunday is 0 or 7, Monday is 1. (Example: "0,5") */
  day_of_week: Scalars['String'];
  /** Cron Hours to Run. Use "*" for "all". (Example: "8,20") */
  hour: Scalars['String'];
  id: Scalars['ID'];
  /** Cron Minutes to Run. Use "*" for "all". (Example: "0,30") */
  minute: Scalars['String'];
  /** Cron Months (1-12) Of The Year to Run. Use "*" for "all". (Example: "1,12") */
  month_of_year: Scalars['String'];
};

export type CeleryPeriodicTask = {
  __typename?: 'CeleryPeriodicTask';
  /** JSON encoded positional arguments (Example: ["arg1", "arg2"]) */
  args: Scalars['String'];
  id: Scalars['ID'];
  /** JSON encoded keyword arguments (Example: {"argument": "value"}) */
  kwargs: Scalars['String'];
  /** Short Description For This Task */
  name: Scalars['String'];
};

export type ChainDeploymentByStatusCount = {
  __typename?: 'ChainDeploymentByStatusCount';
  count: Maybe<Scalars['Int']>;
  status: Maybe<ChainDeploymentStatus>;
};

/** An enumeration. */
export enum ChainDeploymentStatus {
  BuildingModel = 'BUILDING_MODEL',
  BuildingModelFailed = 'BUILDING_MODEL_FAILED',
  BuildingModelStopped = 'BUILDING_MODEL_STOPPED',
  DeactivatedModel = 'DEACTIVATED_MODEL',
  DeactivatingModel = 'DEACTIVATING_MODEL',
  DeployingModel = 'DEPLOYING_MODEL',
  MigratingWorkloadPlanes = 'MIGRATING_WORKLOAD_PLANES',
  ModelDeployFailed = 'MODEL_DEPLOY_FAILED',
  ModelDneError = 'MODEL_DNE_ERROR',
  ModelLoading = 'MODEL_LOADING',
  ModelReady = 'MODEL_READY',
  ModelUnhealthy = 'MODEL_UNHEALTHY',
  ScaledToZero = 'SCALED_TO_ZERO',
  ScalingFromZero = 'SCALING_FROM_ZERO',
  Updating = 'UPDATING'
}

export type ChainDeploymentType = {
  __typename?: 'ChainDeploymentType';
  chain: ChainType;
  chainlets: Array<ChainletType>;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  in_progress_environment_release: Maybe<ChainEnvironmentReleaseType>;
  is_draft: Scalars['Boolean'];
  is_primary: Scalars['Boolean'];
  last_time_deployed: Maybe<Scalars['String']>;
  status: Maybe<ChainDeploymentStatus>;
  user: UserType;
};

/** An enumeration. */
export enum ChainEnvironmentReleaseStatus {
  /** CANCELED */
  Canceled = 'CANCELED',
  /** FAILED */
  Failed = 'FAILED',
  /** RAMPING_DOWN */
  RampingDown = 'RAMPING_DOWN',
  /** RAMPING_UP */
  RampingUp = 'RAMPING_UP',
  /** RELEASING */
  Releasing = 'RELEASING',
  /** SUCCEEDED */
  Succeeded = 'SUCCEEDED'
}

export type ChainEnvironmentReleaseType = {
  __typename?: 'ChainEnvironmentReleaseType';
  chain_deployment: ChainDeploymentType;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  releaser: UserType;
  status: ChainEnvironmentReleaseStatus;
};

export type ChainStableEnvironmentType = {
  __typename?: 'ChainStableEnvironmentType';
  created: Scalars['DateTime'];
  current_chain_deployment: Maybe<ChainDeploymentType>;
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  name: Scalars['String'];
  ramp_up_duration_seconds: Maybe<Scalars['Int']>;
  ramp_up_step_size: Maybe<Scalars['Int']>;
};

export type ChainType = {
  __typename?: 'ChainType';
  created: Scalars['DateTime'];
  deployment_count_by_status: Maybe<Array<Maybe<ChainDeploymentByStatusCount>>>;
  deployments: Array<ChainDeploymentType>;
  id: Scalars['ID'];
  in_progress_environment_release: Maybe<ChainEnvironmentReleaseType>;
  last_activity: Maybe<Scalars['String']>;
  modified: Scalars['DateTime'];
  name: Maybe<Scalars['String']>;
  primary_deployment: Maybe<ChainDeploymentType>;
};

export type ChainletInputGraphene = {
  is_entrypoint?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  oracle_version_id?: InputMaybe<Scalars['String']>;
};

export type ChainletType = {
  __typename?: 'ChainletType';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  is_entrypoint: Scalars['Boolean'];
  name: Maybe<Scalars['String']>;
  oracle: ModelType;
  oracle_version: ModelVersionType;
};

export type ClassType = {
  __typename?: 'ClassType';
  created: Scalars['DateTime'];
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  name: Scalars['String'];
  namespace: Scalars['String'];
  schema: Scalars['JSONString'];
  table_name: Maybe<Scalars['String']>;
  typename: Scalars['String'];
};

export type CodeBaseType = {
  __typename?: 'CodeBaseType';
  code_file_versions: Array<CodeFileType>;
  code_files: Maybe<Array<Maybe<CodeFileType>>>;
  created: Maybe<Scalars['DateTime']>;
  deleted: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['String']>;
  modified: Maybe<Scalars['DateTime']>;
  org: ExistingOrganizationToJoinType;
  version_id: Maybe<Scalars['String']>;
  workflow_versions: Array<OperatorWorkflowType>;
};

export type CodeFileType = {
  __typename?: 'CodeFileType';
  content: Scalars['String'];
  created: Maybe<Scalars['DateTime']>;
  entrypoints: Maybe<Array<Maybe<FileEntrypoint>>>;
  filepath: Scalars['String'];
  id: Maybe<Scalars['String']>;
  modified: Maybe<Scalars['DateTime']>;
  version_id: Maybe<Scalars['String']>;
};

export type CreateAndPublishRelease = {
  __typename?: 'CreateAndPublishRelease';
  release_deployment: Maybe<ReleaseDeploymentType>;
};

export type CreateChainStableEnvironment = {
  __typename?: 'CreateChainStableEnvironment';
  id: Maybe<Scalars['ID']>;
  name: Maybe<Scalars['String']>;
};

export type CreateModelFromTruss = {
  __typename?: 'CreateModelFromTruss';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  user: Maybe<UserType>;
  version_id: Maybe<Scalars['String']>;
};

export type CreateModelVersionFromTruss = {
  __typename?: 'CreateModelVersionFromTruss';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  user: Maybe<UserType>;
  version_id: Maybe<Scalars['String']>;
};

export type CreateOrUpdateCodeFile = {
  __typename?: 'CreateOrUpdateCodeFile';
  code_file: Maybe<CodeFileType>;
};

export type CreateOrUpdateDataSource = {
  __typename?: 'CreateOrUpdateDataSource';
  data_source: Maybe<DataSourceType>;
};

export type CreateOrUpdateSecret = {
  __typename?: 'CreateOrUpdateSecret';
  secret: Maybe<SecretType>;
};

export type CreateOrUpdateWorkletSchedule = {
  __typename?: 'CreateOrUpdateWorkletSchedule';
  worklet_schedule: Maybe<WorkletScheduleType>;
};

export type CreateOracleStableEnvironment = {
  __typename?: 'CreateOracleStableEnvironment';
  id: Maybe<Scalars['ID']>;
  name: Maybe<Scalars['String']>;
};

export type CreatePyNodeRequirement = {
  __typename?: 'CreatePyNodeRequirement';
  requirement: Maybe<PyNodeRequirementType>;
};

export type CreateUserApiKey = {
  __typename?: 'CreateUserAPIKey';
  created: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  prefix: Maybe<Scalars['String']>;
  text_key: Maybe<Scalars['String']>;
  user: Maybe<UserType>;
};

export type CreateView = {
  __typename?: 'CreateView';
  components: Maybe<Scalars['GenericScalar']>;
  current_version_id: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

export type CreateWebhookSigningSecret = {
  __typename?: 'CreateWebhookSigningSecret';
  webhook_signing_secret: Maybe<WebhookSigningSecretType>;
  webhook_signing_secret_text: Maybe<Scalars['String']>;
};

export type CreateWorkflow = {
  __typename?: 'CreateWorkflow';
  builder_version: Maybe<Scalars['Int']>;
  created: Maybe<Scalars['DateTime']>;
  creators: Maybe<Array<Maybe<UserType>>>;
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  operators: Maybe<Array<Maybe<UserType>>>;
  user: Maybe<UserType>;
};

export type CreateWorkspaceApiKey = {
  __typename?: 'CreateWorkspaceAPIKey';
  created: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['String']>;
  prefix: Maybe<Scalars['String']>;
  text_key: Maybe<Scalars['String']>;
};

export type CurrentBillingPeriodUsage = {
  __typename?: 'CurrentBillingPeriodUsage';
  billing_period: Maybe<BillingPeriod>;
  /** @deprecated Use billing_period.end instead */
  current_billing_period_end: Maybe<Scalars['DateTime']>;
  /** @deprecated Use billing_period.start instead */
  current_billing_period_start: Maybe<Scalars['DateTime']>;
  current_period_total: Maybe<Scalars['Decimal']>;
  monetary_credit_usage: Maybe<Scalars['Int']>;
  product_category_usages: Maybe<Array<Maybe<ProductCategoryUsage>>>;
};

export type CurrentModelUsageType = {
  __typename?: 'CurrentModelUsageType';
  current_billing_period_end: Maybe<Scalars['DateTime']>;
  current_billing_period_start: Maybe<Scalars['DateTime']>;
  current_period_total: Maybe<Scalars['Decimal']>;
  usage_per_day: Maybe<Array<Maybe<UsagePerDay>>>;
};

export type DataSourceType = {
  __typename?: 'DataSourceType';
  connections: Maybe<Array<Maybe<ExternalConnectionType>>>;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  name: Scalars['String'];
  type: Maybe<Scalars['String']>;
  use_production_settings_for_draft: Scalars['Boolean'];
};

export type DeactivateChainDeployment = {
  __typename?: 'DeactivateChainDeployment';
  chain_deployment: Maybe<ChainDeploymentType>;
  ok: Maybe<Scalars['Boolean']>;
  primary_chain_deployment: Maybe<ChainDeploymentType>;
};

export type DeactivateModelVersion = {
  __typename?: 'DeactivateModelVersion';
  model_version: Maybe<ModelVersionType>;
  ok: Maybe<Scalars['Boolean']>;
  primary_version: Maybe<ModelVersionType>;
};

export type DeleteChain = {
  __typename?: 'DeleteChain';
  ok: Maybe<Scalars['Boolean']>;
};

export type DeleteChainDeployment = {
  __typename?: 'DeleteChainDeployment';
  ok: Maybe<Scalars['Boolean']>;
};

export type DeleteCodeFile = {
  __typename?: 'DeleteCodeFile';
  ok: Maybe<Scalars['Boolean']>;
};

export type DeleteDataSource = {
  __typename?: 'DeleteDataSource';
  ok: Maybe<Scalars['Boolean']>;
};

export type DeleteImportedGitRepository = {
  __typename?: 'DeleteImportedGitRepository';
  ok: Maybe<Scalars['Boolean']>;
};

export type DeleteModel = {
  __typename?: 'DeleteModel';
  ok: Maybe<Scalars['Boolean']>;
};

export type DeleteModelVersion = {
  __typename?: 'DeleteModelVersion';
  did_delete_model: Maybe<Scalars['Boolean']>;
  ok: Maybe<Scalars['Boolean']>;
};

export type DeleteQuery = {
  __typename?: 'DeleteQuery';
  ok: Maybe<Scalars['Boolean']>;
};

export type DeleteSecret = {
  __typename?: 'DeleteSecret';
  ok: Maybe<Scalars['Boolean']>;
};

export type DeleteSharedState = {
  __typename?: 'DeleteSharedState';
  id: Maybe<Scalars['String']>;
};

export type DeleteView = {
  __typename?: 'DeleteView';
  ok: Maybe<Scalars['Boolean']>;
};

export type DeleteWebhookSigningSecret = {
  __typename?: 'DeleteWebhookSigningSecret';
  ok: Maybe<Scalars['Boolean']>;
};

export type DeleteWorkflow = {
  __typename?: 'DeleteWorkflow';
  ok: Maybe<Scalars['Boolean']>;
};

export type DeleteWorklet = {
  __typename?: 'DeleteWorklet';
  ok: Maybe<Scalars['Boolean']>;
};

export type DeleteWorkletSchedule = {
  __typename?: 'DeleteWorkletSchedule';
  deleted: Maybe<Scalars['Boolean']>;
};

export type DeployChain = {
  __typename?: 'DeployChain';
  chain_deployment_id: Maybe<Scalars['String']>;
  chain_id: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  ok: Maybe<Scalars['Boolean']>;
};

export type DeployChainDeployment = {
  __typename?: 'DeployChainDeployment';
  chain_deployment_id: Maybe<Scalars['String']>;
  chain_id: Maybe<Scalars['String']>;
  ok: Maybe<Scalars['Boolean']>;
};

export type DeployDraftChain = {
  __typename?: 'DeployDraftChain';
  chain_deployment_id: Maybe<Scalars['String']>;
  chain_id: Maybe<Scalars['String']>;
  ok: Maybe<Scalars['Boolean']>;
};

export type DeployDraftTruss = {
  __typename?: 'DeployDraftTruss';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  user: Maybe<UserType>;
  version_id: Maybe<Scalars['String']>;
};

export type DeployLibraryModel = {
  __typename?: 'DeployLibraryModel';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  user: Maybe<UserType>;
  version_id: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum DeploymentEnvEnum {
  Draft = 'DRAFT',
  Production = 'PRODUCTION'
}

export type DeploymentSpecType = {
  __typename?: 'DeploymentSpecType';
  id: Maybe<Scalars['String']>;
  workload_plane: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum DeploymentStatus {
  BuildingModel = 'BUILDING_MODEL',
  BuildingModelFailed = 'BUILDING_MODEL_FAILED',
  BuildingModelStopped = 'BUILDING_MODEL_STOPPED',
  DeactivatedModel = 'DEACTIVATED_MODEL',
  DeactivatingModel = 'DEACTIVATING_MODEL',
  DeployingModel = 'DEPLOYING_MODEL',
  MigratingWorkloadPlanes = 'MIGRATING_WORKLOAD_PLANES',
  ModelDeployFailed = 'MODEL_DEPLOY_FAILED',
  ModelDneError = 'MODEL_DNE_ERROR',
  ModelLoading = 'MODEL_LOADING',
  ModelReady = 'MODEL_READY',
  ModelUnhealthy = 'MODEL_UNHEALTHY',
  ScaledToZero = 'SCALED_TO_ZERO',
  ScalingFromZero = 'SCALING_FROM_ZERO',
  Updating = 'UPDATING'
}

/** An enumeration. */
export enum DeploymentStatusReason {
  ExhaustedCredit = 'EXHAUSTED_CREDIT',
  Hibernation = 'HIBERNATION',
  ModelInactivity = 'MODEL_INACTIVITY',
  PaymentFailed = 'PAYMENT_FAILED'
}

export type DisableWorkletSchedule = {
  __typename?: 'DisableWorkletSchedule';
  disabled: Maybe<Scalars['Boolean']>;
};

export type DuplicateApplication = {
  __typename?: 'DuplicateApplication';
  workflow: Maybe<WorkflowType>;
};

export type DuplicateView = {
  __typename?: 'DuplicateView';
  view: Maybe<PubliclyVisibleViewType>;
};

export type DuplicateWorklet = {
  __typename?: 'DuplicateWorklet';
  worklet: Maybe<WorkletType>;
};

/** Used to return billed entities as a part of the bill page. */
export type Entity = ModelVersionTombstone | ModelVersionType;

export type EntityRelation = RelatedEntityList | RelatedEntitySingleton;

/**
 * This type is a wrapper around PubliclyVisibleViewType that includes which env should
 * be used to invoke worklets and run queries from that view.
 */
export type EnvAwarePubliclyVisibleViewType = {
  __typename?: 'EnvAwarePubliclyVisibleViewType';
  from_environment: Maybe<DeploymentEnvEnum>;
  is_starter_app: Maybe<Scalars['Boolean']>;
  shared_states: Maybe<Array<Maybe<Scalars['String']>>>;
  should_show_nav: Maybe<Scalars['Boolean']>;
  view: Maybe<PubliclyVisibleViewType>;
  workflow_name: Maybe<Scalars['String']>;
};

export type ExistingOrganizationToJoinType = {
  __typename?: 'ExistingOrganizationToJoinType';
  admin_name: Maybe<Scalars['String']>;
  /** User-configured workspace name */
  display_name: Maybe<Scalars['String']>;
  /** If join_existing_setting is ENABLED, users with the specified email domain can join the org. */
  domain: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum ExternalConnectionConnectionType {
  /** BIGQUERY */
  Bigquery = 'BIGQUERY',
  /** KAFKA */
  Kafka = 'KAFKA',
  /** MYSQL */
  Mysql = 'MYSQL',
  /** POSTGRES */
  Postgres = 'POSTGRES',
  /** REDIS */
  Redis = 'REDIS',
  /** S3 */
  S3 = 'S3',
  /** SAGE_MAKER */
  SageMaker = 'SAGE_MAKER',
  /** SNOWFLAKE */
  Snowflake = 'SNOWFLAKE'
}

export type ExternalConnectionType = {
  __typename?: 'ExternalConnectionType';
  connection_name: Scalars['String'];
  connection_parameters: Maybe<Scalars['GenericScalar']>;
  connection_type: ExternalConnectionConnectionType;
  created: Scalars['DateTime'];
  data_schema: Scalars['JSONString'];
  deployment_env: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_streaming: Maybe<Scalars['Boolean']>;
  modified: Scalars['DateTime'];
};

export type FeatureFlagType = {
  __typename?: 'FeatureFlagType';
  enabled: Maybe<Scalars['Boolean']>;
  feature_flag: Maybe<Scalars['String']>;
};

export type FileEntrypoint = {
  __typename?: 'FileEntrypoint';
  line_no: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
};

export type GenerateStripeSetupIntent = {
  __typename?: 'GenerateStripeSetupIntent';
  client_secret: Maybe<Scalars['String']>;
};

/** Gets user token for invoking model. */
export type GetInvokeModelUserTokenMutation = {
  __typename?: 'GetInvokeModelUserTokenMutation';
  token: Maybe<Scalars['String']>;
};

export type GitIntegrationPushType = {
  __typename?: 'GitIntegrationPushType';
  error_message: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status: GitPushStatus;
};

/** An enumeration. */
export enum GitPushStatus {
  /** FAILED */
  Failed = 'FAILED',
  /** PENDING */
  Pending = 'PENDING',
  /** PUSHING */
  Pushing = 'PUSHING',
  /** SUCCEEDED */
  Succeeded = 'SUCCEEDED'
}

/**
 *
 *         Available GPU types for an oracle. The value will be lowered and passed to the 'karpenter.k8s.aws/instance-gpu-name'
 *         node selector. All AWS supported values can be found here https://karpenter.sh/v0.18.1/aws/instance-types/
 *
 */
export enum GpuType {
  A10G = 'A10G',
  A100 = 'A100',
  H100 = 'H100',
  H100_40Gb = 'H100_40GB',
  L4 = 'L4',
  T4 = 'T4',
  V100 = 'V100'
}

export type HijackAction = {
  __typename?: 'HijackAction';
  name: Maybe<Scalars['String']>;
  next_url: Maybe<Scalars['String']>;
  user_id: Maybe<Scalars['String']>;
};

export type ImportGitRepository = {
  __typename?: 'ImportGitRepository';
  imported_git_repository: Maybe<ImportedGitRepositoryType>;
};

export type ImportedGitRepositoryType = {
  __typename?: 'ImportedGitRepositoryType';
  available_branches: Maybe<Array<Maybe<Scalars['String']>>>;
  branch_name: Scalars['String'];
  commit_sha: Scalars['String'];
  github_url: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  last_failed_at: Maybe<Scalars['DateTime']>;
  modified: Scalars['DateTime'];
  repository_full_name: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
};

export type InferenceVolumeByStatusDatapoint = {
  __typename?: 'InferenceVolumeByStatusDatapoint';
  status_2xx: Scalars['Float'];
  status_4xx: Scalars['Float'];
  status_5xx: Scalars['Float'];
  timestamp: Scalars['DateTime'];
};

export type InstanceType = {
  __typename?: 'InstanceType';
  default: Scalars['Boolean'];
  display_name: Scalars['String'];
  gpu_count: Scalars['Int'];
  gpu_memory: Scalars['Int'];
  gpu_type: Maybe<GpuType>;
  limited_capacity: Maybe<Scalars['Boolean']>;
  memory_limit: Maybe<Scalars['Int']>;
  millicpu_limit: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  price: Maybe<Scalars['Decimal']>;
};

export type InstantModelMetrics = {
  __typename?: 'InstantModelMetrics';
  /** Average number of requests in the async queue */
  average_async_queue_size: Maybe<Scalars['Float']>;
  /** Average of the response time distribution, including any queueing and routing time */
  average_e2e_response_time: Maybe<Scalars['Int']>;
  /** Average of the response time distribution */
  average_response_time: Maybe<Scalars['Int']>;
  /** Average time spent in the async queue */
  average_time_in_async_queue: Maybe<Scalars['Float']>;
  /** CPU usage measured in cores, averaged over all model replicas */
  cpu_usage: Maybe<Scalars['Float']>;
  /** Percentiles of the response time distribution, including any queueing and routing time */
  e2e_response_time_percentiles: Maybe<ResponseTimeDatapoint>;
  /** GPU memory usage measured in bytes, averaged over all model replicas */
  gpu_memory_usage: Maybe<Scalars['Float']>;
  /** Fractional GPU utilization, averaged over all model replicas. 1 = full utilization, 0 = no utilization */
  gpu_usage: Maybe<Scalars['Float']>;
  /** Number of inference requests per unit time */
  inference_volume: Maybe<Scalars['Float']>;
  /** Number of inference requests per unit time,  broken down by HTTP status code range */
  inference_volume_by_status: Maybe<InferenceVolumeByStatusDatapoint>;
  /** Memory usage measured in bytes, averaged over all model replicas */
  memory_usage: Maybe<Scalars['Float']>;
  /** Percentiles of the response time distribution */
  response_time_percentiles: Maybe<ResponseTimeDatapoint>;
  /** Percentiles of the time spent in the async queue */
  time_in_async_queue_percentiles: Maybe<ResponseTimeDatapoint>;
};

export type IntegrationType = {
  __typename?: 'IntegrationType';
  available_git_repositories_to_import: Maybe<Array<Maybe<AvailableGitRepositoryToImport>>>;
  created: Scalars['DateTime'];
  installation_type: Maybe<OrganizationGitProviderIntegrationInstallationType>;
  installer_name: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  status: Maybe<OrganizationGitProviderIntegrationStatus>;
};


export type IntegrationTypeAvailable_Git_Repositories_To_ImportArgs = {
  page?: InputMaybe<Scalars['Int']>;
};

export type InviteUser = {
  __typename?: 'InviteUser';
  error: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  invited: Maybe<Scalars['Boolean']>;
};

export type InvitedUserType = {
  __typename?: 'InvitedUserType';
  account_created_at: Maybe<Scalars['DateTime']>;
  email: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invited: Scalars['Boolean'];
  invited_by: Maybe<UserType>;
  role_name: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum InvoiceStatus {
  Draft = 'DRAFT',
  Open = 'OPEN',
  Paid = 'PAID',
  Uncollectible = 'UNCOLLECTIBLE',
  Void = 'VOID'
}

/**
 * Enum representing whether and how the join existing workspace feature
 *         is enabled.
 *
 */
export enum JoinExistingSetting {
  Disabled = 'DISABLED',
  DisabledWithUnknownEligibility = 'DISABLED_WITH_UNKNOWN_ELIGIBILITY',
  Enabled = 'ENABLED',
  Ineligible = 'INELIGIBLE'
}

/** An enumeration. */
export enum Levels {
  Basic = 'BASIC',
  Blueprint = 'BLUEPRINT',
  Business = 'BUSINESS',
  Starter = 'STARTER',
  Startup = 'STARTUP'
}

export type LogEvent = {
  __typename?: 'LogEvent';
  ok: Maybe<Scalars['Boolean']>;
};

export type ManageRequestToJoinOrganization = {
  __typename?: 'ManageRequestToJoinOrganization';
  ok: Maybe<Scalars['Boolean']>;
};

export type MigrationInfoRequestType = {
  oracle_migration_infos: Array<InputMaybe<OracleMigrationInfoRequestType>>;
};

export type MigrationInfoType = {
  __typename?: 'MigrationInfoType';
  oracle_migration_infos: Maybe<Array<Maybe<OracleMigrationInfoType>>>;
};

export type ModelDeploymentSpecType = {
  __typename?: 'ModelDeploymentSpecType';
  id: Scalars['ID'];
  idle_time_seconds: Maybe<Scalars['Int']>;
  instance_type: Maybe<InstanceType>;
  max_replica: Scalars['Int'];
  min_replica: Scalars['Int'];
  parallelism: Scalars['Int'];
  scaling_window_seconds: Scalars['Int'];
};

export type ModelDeploymentStatusType = {
  __typename?: 'ModelDeploymentStatusType';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  reason: Maybe<DeploymentStatusReason>;
  status: Maybe<DeploymentStatus>;
};

export type ModelDeploymentType = {
  __typename?: 'ModelDeploymentType';
  gpu_count: Scalars['Int'];
  id: Scalars['ID'];
  idle_time_seconds: Maybe<Scalars['Int']>;
  max_replica: Scalars['Int'];
  min_replica: Scalars['Int'];
  parallelism: Scalars['Int'];
  scaling_window_seconds: Maybe<Scalars['Int']>;
};

export enum ModelOriginType {
  Baseten = 'BASETEN',
  Blueprint = 'BLUEPRINT',
  Chains = 'CHAINS'
}

/** An enumeration. */
export enum ModelPlatformType {
  Baseten = 'BASETEN',
  ModelLibrary = 'MODEL_LIBRARY',
  ModelZoo = 'MODEL_ZOO',
  SageMaker = 'SAGE_MAKER'
}

export type ModelProductionReleaseType = {
  __typename?: 'ModelProductionReleaseType';
  created: Scalars['DateTime'];
  error_message: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  oracle_version: ModelVersionType;
  percent_traffic_to_new_version: Scalars['Int'];
  ramp_up_duration_seconds: Maybe<Scalars['Int']>;
  ramp_up_started_at: Maybe<Scalars['DateTime']>;
  releaser: UserType;
  stable_environment_name: Maybe<Scalars['String']>;
  status: OracleProductionReleaseStatus;
};

export type ModelS3UploadCredentials = {
  __typename?: 'ModelS3UploadCredentials';
  aws_access_key_id: Maybe<Scalars['String']>;
  aws_secret_access_key: Maybe<Scalars['String']>;
  aws_session_token: Maybe<Scalars['String']>;
  s3_bucket: Maybe<Scalars['String']>;
  s3_key: Maybe<Scalars['String']>;
};

export type ModelType = {
  __typename?: 'ModelType';
  created: Scalars['DateTime'];
  current_deployment_spec: Maybe<ModelDeploymentSpecType>;
  current_version: Maybe<ModelVersionType>;
  derived_from_library_model_name: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  in_progress_production_release: Maybe<ModelProductionReleaseType>;
  last_activity: Maybe<Scalars['String']>;
  model_card_markdown: Maybe<Scalars['String']>;
  model_platform_type: Maybe<ModelPlatformType>;
  modified: Scalars['DateTime'];
  name: Maybe<Scalars['String']>;
  next_minor_semver: Maybe<Scalars['String']>;
  number_of_versions: Maybe<Scalars['Int']>;
  primary_version: Maybe<ModelVersionType>;
  ramp_up_duration_seconds: Maybe<Scalars['Int']>;
  ramp_up_step_size: Maybe<Scalars['Int']>;
  ramp_up_while_promoting: Maybe<Scalars['Boolean']>;
  version_count_by_status: Maybe<Array<Maybe<ModelVersionByStatusCount>>>;
  versions: Array<ModelVersionType>;
};

export type ModelVersionByStatusCount = {
  __typename?: 'ModelVersionByStatusCount';
  count: Maybe<Scalars['Int']>;
  status: Maybe<DeploymentStatus>;
};

/** Used to represent model versions that have been deleted. */
export type ModelVersionTombstone = {
  __typename?: 'ModelVersionTombstone';
  id: Scalars['ID'];
  model_name: Maybe<Scalars['String']>;
};

export type ModelVersionType = {
  __typename?: 'ModelVersionType';
  active_replica_count: Maybe<Scalars['Int']>;
  avatar_url: Maybe<Scalars['String']>;
  class_labels: Maybe<Scalars['GenericScalar']>;
  cover_image_url: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  current_deployment: Maybe<ModelDeploymentType>;
  /** @deprecated In favor of current_model_deployment_status */
  current_deployment_status: Maybe<DeploymentStatus>;
  current_model_deployment_status: Maybe<ModelDeploymentStatusType>;
  example_model_input: Maybe<Scalars['String']>;
  example_model_input_json: Maybe<Scalars['String']>;
  example_model_input_json_shell_escaped: Maybe<Scalars['String']>;
  external_model_version_id: Maybe<Scalars['String']>;
  feature_names: Maybe<Scalars['GenericScalar']>;
  feature_summary: Maybe<Scalars['GenericScalar']>;
  id: Scalars['ID'];
  idle_time_minutes: Maybe<Scalars['Int']>;
  input_shape: Maybe<Scalars['GenericScalar']>;
  invoke_base_url: Maybe<Scalars['String']>;
  is_deployed: Maybe<Scalars['Boolean']>;
  is_development: Scalars['Boolean'];
  is_draft: Maybe<Scalars['Boolean']>;
  is_primary: Scalars['Boolean'];
  is_proxied_model: Maybe<Scalars['Boolean']>;
  last_time_deployed: Maybe<Scalars['String']>;
  live_deployment: Maybe<ModelDeploymentType>;
  model_framework: Scalars['String'];
  model_id: Maybe<Scalars['String']>;
  model_platform_type: Maybe<ModelPlatformType>;
  model_type: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  oracle: ModelType;
  s3_key: Maybe<Scalars['String']>;
  semver: Scalars['String'];
  size: Maybe<Scalars['Int']>;
  tags: Maybe<Array<Maybe<Scalars['String']>>>;
  total_time_running: Maybe<Scalars['Int']>;
  truss_context_builder_version: Maybe<Scalars['String']>;
  truss_hash: Maybe<Scalars['String']>;
  truss_signature: Maybe<Scalars['JSONString']>;
  truss_spec_version: Maybe<Scalars['String']>;
  user: UserType;
};

/** This class will inherit from multiple Mutations. One mutation per model. */
export type Mutation = {
  __typename?: 'Mutation';
  activate_chain_deployment: Maybe<ActivateChainDeployment>;
  activate_model_version: Maybe<ActivateModelVersion>;
  add_shared_state: Maybe<AddSharedState>;
  cancel_model_deployment: Maybe<CancelModelDeployment>;
  cancel_model_version_promotion: Maybe<CancelModelVersionPromotion>;
  create_and_publish_release: Maybe<CreateAndPublishRelease>;
  create_chain_stable_environment: Maybe<CreateChainStableEnvironment>;
  create_model_from_truss: Maybe<CreateModelFromTruss>;
  create_model_version_from_truss: Maybe<CreateModelVersionFromTruss>;
  create_or_update_code_file: Maybe<CreateOrUpdateCodeFile>;
  create_or_update_data_source: Maybe<CreateOrUpdateDataSource>;
  create_or_update_secret: Maybe<CreateOrUpdateSecret>;
  create_or_update_worklet_schedule: Maybe<CreateOrUpdateWorkletSchedule>;
  create_oracle_stable_environment: Maybe<CreateOracleStableEnvironment>;
  create_pynode_requirement: Maybe<CreatePyNodeRequirement>;
  create_query: Maybe<UpdateOrCreateQuery>;
  create_user_api_key: Maybe<CreateUserApiKey>;
  create_view: Maybe<CreateView>;
  create_webhook_signing_secret: Maybe<CreateWebhookSigningSecret>;
  create_workflow: Maybe<CreateWorkflow>;
  create_workspace_api_key: Maybe<CreateWorkspaceApiKey>;
  deactivate_chain_deployment: Maybe<DeactivateChainDeployment>;
  deactivate_model_version: Maybe<DeactivateModelVersion>;
  delete_chain: Maybe<DeleteChain>;
  delete_chain_deployment: Maybe<DeleteChainDeployment>;
  delete_code_file: Maybe<DeleteCodeFile>;
  delete_data_source: Maybe<DeleteDataSource>;
  delete_imported_git_repository: Maybe<DeleteImportedGitRepository>;
  delete_model: Maybe<DeleteModel>;
  delete_model_version: Maybe<DeleteModelVersion>;
  delete_query: Maybe<DeleteQuery>;
  delete_secret: Maybe<DeleteSecret>;
  delete_shared_state: Maybe<DeleteSharedState>;
  delete_view: Maybe<DeleteView>;
  delete_webhook_signing_secret: Maybe<DeleteWebhookSigningSecret>;
  delete_workflow: Maybe<DeleteWorkflow>;
  delete_worklet: Maybe<DeleteWorklet>;
  delete_worklet_schedule: Maybe<DeleteWorkletSchedule>;
  deploy_chain: Maybe<DeployChain>;
  deploy_chain_deployment: Maybe<DeployChainDeployment>;
  deploy_draft_chain: Maybe<DeployDraftChain>;
  deploy_draft_truss: Maybe<DeployDraftTruss>;
  deploy_library_model: Maybe<DeployLibraryModel>;
  disable_worklet_schedule: Maybe<DisableWorkletSchedule>;
  duplicate_application: Maybe<DuplicateApplication>;
  duplicate_view: Maybe<DuplicateView>;
  duplicate_worklet: Maybe<DuplicateWorklet>;
  generate_stripe_setup_intent: Maybe<GenerateStripeSetupIntent>;
  /** Gets user token for invoking model. */
  get_invoke_model_user_token: Maybe<GetInvokeModelUserTokenMutation>;
  import_git_repository: Maybe<ImportGitRepository>;
  invite_user: Maybe<InviteUser>;
  log_event: Maybe<LogEvent>;
  manage_request_to_join: Maybe<ManageRequestToJoinOrganization>;
  patch_draft_truss: Maybe<PatchDraftTruss>;
  promote_draft_model_version: Maybe<PromoteDraftModelVersion>;
  promote_oracle_version_to_stable_environment: Maybe<PromoteOracleVersionToStableEnvironment>;
  remove_invited_user: Maybe<RemoveInvitedUser>;
  remove_user_role: Maybe<RemoveUserRole>;
  request_to_join: Maybe<RequestToJoinOrganization>;
  retry_model_deployment: Maybe<RetryModelDeployment>;
  revoke_user_api_key: Maybe<RevokeUserApiKey>;
  rotate_webhook_signing_secret: Maybe<RotateWebhookSigningSecret>;
  run_model_status_check: Maybe<RunModelStatusCheck>;
  run_oracle_migration_tool: Maybe<RunOracleMigrationTool>;
  set_stripe_billing_email: Maybe<SetStripeBillingEmail>;
  set_stripe_default_payment_method: Maybe<SetStripeDefaultPaymentMethod>;
  set_workflow_publicly_shared: Maybe<SetWorkflowPubliclyShared>;
  udm_class_batch_mutation: Maybe<UdmClassBatchMutation>;
  update_autoscaling_settings: Maybe<UpdateModelVersionSettings>;
  update_chain_deployment: Maybe<UpdateChainDeployment>;
  update_chainlet_environment_settings: Maybe<UpdateChainletEnvironmentSettings>;
  update_imported_git_repository: Maybe<UpdateImportedGitRepository>;
  update_invited_user_role: Maybe<UpdateInvitedUserRole>;
  update_model_settings: Maybe<UpdateModelSettings>;
  update_model_version: Maybe<UpdateModelVersion>;
  update_organization: Maybe<UpdateOrganization>;
  update_query_name: Maybe<UpdateQueryName>;
  update_resources: Maybe<UpdateModelResources>;
  update_starting_view: Maybe<UpdateStartingView>;
  update_user: Maybe<UserType>;
  update_user_role: Maybe<UpdateUserRole>;
  update_view: Maybe<UpdateView>;
  update_view_name: Maybe<UpdateViewName>;
  update_workflow: Maybe<UpdateWorkflow>;
  updated_onboarding_state: Maybe<UpdateOnboardingState>;
  updated_walkthrough_state: Maybe<UpdateWalkthroughState>;
  worklet_mutation: Maybe<WorkletMutation>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationActivate_Chain_DeploymentArgs = {
  chain_deployment_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationActivate_Model_VersionArgs = {
  model_version_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationAdd_Shared_StateArgs = {
  state_name?: InputMaybe<Scalars['String']>;
  workflow_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationCancel_Model_DeploymentArgs = {
  model_version_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationCancel_Model_Version_PromotionArgs = {
  model_version_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationCreate_And_Publish_ReleaseArgs = {
  description?: InputMaybe<Scalars['String']>;
  include_latest_requirements?: InputMaybe<Scalars['Boolean']>;
  include_latest_udm?: InputMaybe<Scalars['Boolean']>;
  version?: InputMaybe<Scalars['String']>;
  workflow_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationCreate_Chain_Stable_EnvironmentArgs = {
  chain_id: Scalars['ID'];
  name: Scalars['String'];
  promotion_settings?: InputMaybe<PromotionSettingsType>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationCreate_Model_From_TrussArgs = {
  client_version?: InputMaybe<Scalars['String']>;
  config?: InputMaybe<Scalars['String']>;
  external_model_version_id?: InputMaybe<Scalars['String']>;
  is_trusted?: InputMaybe<Scalars['Boolean']>;
  model_origin?: InputMaybe<ModelOriginType>;
  name?: InputMaybe<Scalars['String']>;
  s3_key?: InputMaybe<Scalars['String']>;
  semver_bump?: InputMaybe<Scalars['String']>;
  version_name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationCreate_Model_Version_From_TrussArgs = {
  client_version?: InputMaybe<Scalars['String']>;
  config?: InputMaybe<Scalars['String']>;
  external_model_version_id?: InputMaybe<Scalars['String']>;
  is_trusted?: InputMaybe<Scalars['Boolean']>;
  model_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  promote_after_deploy?: InputMaybe<Scalars['Boolean']>;
  s3_key?: InputMaybe<Scalars['String']>;
  scale_down_old_production?: InputMaybe<Scalars['Boolean']>;
  semver_bump?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationCreate_Or_Update_Code_FileArgs = {
  code_file_id?: InputMaybe<Scalars['String']>;
  code_file_version_id?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  encoded_code_file_data?: InputMaybe<Scalars['String']>;
  filepath?: InputMaybe<Scalars['String']>;
  workflow_id: Scalars['String'];
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationCreate_Or_Update_Data_SourceArgs = {
  encoded_request?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationCreate_Or_Update_SecretArgs = {
  encoded_request?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationCreate_Or_Update_Worklet_ScheduleArgs = {
  encoded_schedule?: InputMaybe<Scalars['String']>;
  worklet_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationCreate_Oracle_Stable_EnvironmentArgs = {
  autoscaling_settings?: InputMaybe<AutoscalingSettingsType>;
  model_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  promotion_settings?: InputMaybe<PromotionSettingsType>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationCreate_Pynode_RequirementArgs = {
  requirements_txt?: InputMaybe<Scalars['String']>;
  system_requirements?: InputMaybe<Scalars['String']>;
  workflow_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationCreate_QueryArgs = {
  connection_name?: InputMaybe<Scalars['String']>;
  current_version_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  query_id?: InputMaybe<Scalars['String']>;
  query_str?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationCreate_User_Api_KeyArgs = {
  name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationCreate_ViewArgs = {
  config?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  node_id?: InputMaybe<Scalars['String']>;
  workflow_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationCreate_WorkflowArgs = {
  builder_version?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  entry?: InputMaybe<Scalars['String']>;
  model_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationCreate_Workspace_Api_KeyArgs = {
  allowed_model_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  api_key_type?: InputMaybe<ApiKeyType>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDeactivate_Chain_DeploymentArgs = {
  chain_deployment_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDeactivate_Model_VersionArgs = {
  model_version_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDelete_ChainArgs = {
  chain_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDelete_Chain_DeploymentArgs = {
  chain_deployment_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDelete_Code_FileArgs = {
  code_file_id: Scalars['String'];
  workflow_id: Scalars['String'];
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDelete_Data_SourceArgs = {
  data_source_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDelete_Imported_Git_RepositoryArgs = {
  imported_git_repository_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDelete_ModelArgs = {
  model_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDelete_Model_VersionArgs = {
  model_version_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDelete_QueryArgs = {
  current_version_id?: InputMaybe<Scalars['String']>;
  query_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDelete_SecretArgs = {
  secret_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDelete_Shared_StateArgs = {
  state_name?: InputMaybe<Scalars['String']>;
  workflow_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDelete_ViewArgs = {
  current_version_id?: InputMaybe<Scalars['String']>;
  view_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDelete_Webhook_Signing_SecretArgs = {
  webhook_signing_secret_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDelete_WorkflowArgs = {
  workflow_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDelete_WorkletArgs = {
  worklet_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDelete_Worklet_ScheduleArgs = {
  worklet_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDeploy_ChainArgs = {
  chainlets?: InputMaybe<Array<InputMaybe<ChainletInputGraphene>>>;
  name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDeploy_Chain_DeploymentArgs = {
  chain_id?: InputMaybe<Scalars['String']>;
  chainlets?: InputMaybe<Array<InputMaybe<ChainletInputGraphene>>>;
  promote_after_deploy?: InputMaybe<Scalars['Boolean']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDeploy_Draft_ChainArgs = {
  chainlets?: InputMaybe<Array<InputMaybe<ChainletInputGraphene>>>;
  name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDeploy_Draft_TrussArgs = {
  client_version?: InputMaybe<Scalars['String']>;
  config?: InputMaybe<Scalars['String']>;
  is_trusted?: InputMaybe<Scalars['Boolean']>;
  model_origin?: InputMaybe<ModelOriginType>;
  name?: InputMaybe<Scalars['String']>;
  s3_key?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDeploy_Library_ModelArgs = {
  deployed_model_name?: InputMaybe<Scalars['String']>;
  library_model_name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDisable_Worklet_ScheduleArgs = {
  worklet_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDuplicate_ApplicationArgs = {
  application_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDuplicate_ViewArgs = {
  view_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationDuplicate_WorkletArgs = {
  worklet_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationGenerate_Stripe_Setup_IntentArgs = {
  include_bank_account?: InputMaybe<Scalars['Boolean']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationImport_Git_RepositoryArgs = {
  branch_name?: InputMaybe<Scalars['String']>;
  repository_full_name?: InputMaybe<Scalars['String']>;
  workflow_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationInvite_UserArgs = {
  email?: InputMaybe<Scalars['String']>;
  invited_by?: InputMaybe<Scalars['String']>;
  role_name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationLog_EventArgs = {
  name?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationManage_Request_To_JoinArgs = {
  approve?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  role_name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationPatch_Draft_TrussArgs = {
  client_version?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  patch?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationPromote_Draft_Model_VersionArgs = {
  draft_model_version_id?: InputMaybe<Scalars['String']>;
  promote_after_deploy?: InputMaybe<Scalars['Boolean']>;
  scale_down_old_production?: InputMaybe<Scalars['Boolean']>;
  version_name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationPromote_Oracle_Version_To_Stable_EnvironmentArgs = {
  model_version_id?: InputMaybe<Scalars['String']>;
  scale_down_previous_deployment?: InputMaybe<Scalars['Boolean']>;
  stable_environment_name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationRemove_Invited_UserArgs = {
  email?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationRemove_User_RoleArgs = {
  username?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationRequest_To_JoinArgs = {
  join_existing?: InputMaybe<Scalars['Boolean']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationRetry_Model_DeploymentArgs = {
  model_version_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationRevoke_User_Api_KeyArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationRotate_Webhook_Signing_SecretArgs = {
  webhook_signing_secret_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationRun_Model_Status_CheckArgs = {
  model_version_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationRun_Oracle_Migration_ToolArgs = {
  make_primary_scale_up: Scalars['Boolean'];
  oracle_ids: Array<InputMaybe<Scalars['String']>>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationSet_Stripe_Billing_EmailArgs = {
  email?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationSet_Stripe_Default_Payment_MethodArgs = {
  payment_method_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationSet_Workflow_Publicly_SharedArgs = {
  is_publicly_shared?: InputMaybe<Scalars['Boolean']>;
  workflow_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationUdm_Class_Batch_MutationArgs = {
  encoded_action_batch?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationUpdate_Autoscaling_SettingsArgs = {
  idle_time_seconds?: InputMaybe<Scalars['Int']>;
  max_replica?: InputMaybe<Scalars['Int']>;
  min_replica?: InputMaybe<Scalars['Int']>;
  model_version_id?: InputMaybe<Scalars['String']>;
  parallelism?: InputMaybe<Scalars['Int']>;
  scaling_window_seconds?: InputMaybe<Scalars['Int']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationUpdate_Chain_DeploymentArgs = {
  chain_deployment_id?: InputMaybe<Scalars['String']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  scale_down_old_production?: InputMaybe<Scalars['Boolean']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationUpdate_Chainlet_Environment_SettingsArgs = {
  autoscaling_settings?: InputMaybe<AutoscalingSettingsType>;
  chain_deployment_id?: InputMaybe<Scalars['String']>;
  chainlet_name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationUpdate_Imported_Git_RepositoryArgs = {
  branch_name?: InputMaybe<Scalars['String']>;
  imported_git_repository_id?: InputMaybe<Scalars['String']>;
  workflow_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationUpdate_Invited_User_RoleArgs = {
  email?: InputMaybe<Scalars['String']>;
  new_role_name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationUpdate_Model_SettingsArgs = {
  model_id?: InputMaybe<Scalars['String']>;
  ramp_up_duration_seconds?: InputMaybe<Scalars['Int']>;
  ramp_up_while_promoting?: InputMaybe<Scalars['Boolean']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationUpdate_Model_VersionArgs = {
  is_primary?: InputMaybe<Scalars['Boolean']>;
  model_version_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  scale_down_old_production?: InputMaybe<Scalars['Boolean']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationUpdate_OrganizationArgs = {
  billing_email?: InputMaybe<Scalars['String']>;
  join_existing_setting?: InputMaybe<JoinExistingSetting>;
  scale_new_models_to_zero?: InputMaybe<Scalars['Boolean']>;
  workspace_name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationUpdate_Query_NameArgs = {
  current_version_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationUpdate_ResourcesArgs = {
  instance_type_label?: InputMaybe<Scalars['String']>;
  model_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationUpdate_Starting_ViewArgs = {
  new_starting_view_id?: InputMaybe<Scalars['String']>;
  workflow_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationUpdate_UserArgs = {
  update_user_input: UpdateUserInput;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationUpdate_User_RoleArgs = {
  new_role_name?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationUpdate_ViewArgs = {
  config?: InputMaybe<Scalars['String']>;
  current_version_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  view_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationUpdate_View_NameArgs = {
  current_version_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  view_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationUpdate_WorkflowArgs = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  is_synced_to_git?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationUpdated_Onboarding_StateArgs = {
  state?: InputMaybe<Scalars['GenericScalar']>;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationUpdated_Walkthrough_StateArgs = {
  state: WalkthroughState;
};


/** This class will inherit from multiple Mutations. One mutation per model. */
export type MutationWorklet_MutationArgs = {
  current_version_id?: InputMaybe<Scalars['String']>;
  encoded_action?: InputMaybe<Scalars['String']>;
  worklet_id?: InputMaybe<Scalars['String']>;
};

export type NavigateAction = {
  __typename?: 'NavigateAction';
  name: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum NodeInvocationStatus {
  /** BRANCH */
  Branch = 'BRANCH',
  /** FAILURE */
  Failure = 'FAILURE',
  /** FORK */
  Fork = 'FORK',
  /** SUCCESS */
  Success = 'SUCCESS',
  /** SUSPEND */
  Suspend = 'SUSPEND'
}

export type NodeInvocationType = {
  __typename?: 'NodeInvocationType';
  created: Scalars['DateTime'];
  error_message: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  input_data: Maybe<Scalars['String']>;
  stack_trace: Maybe<Scalars['JSONString']>;
  status: NodeInvocationStatus;
};

export type NodeType = {
  __typename?: 'NodeType';
  atom: Scalars['JSONString'];
  created: Scalars['DateTime'];
  group_name: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  signature: Maybe<AtomSignature>;
  view_node_views: Maybe<Array<Maybe<PubliclyVisibleViewType>>>;
};

export type OnboardingChecklistType = {
  __typename?: 'OnboardingChecklistType';
  finished_onboarding_flow: Scalars['Boolean'];
  onboarded: Scalars['Boolean'];
  onboarding_state: Scalars['JSONString'];
  walkthrough_state: OnboardingChecklistWalkthroughState;
};

/** An enumeration. */
export enum OnboardingChecklistWalkthroughState {
  /** Codefile */
  Codefile = 'CODEFILE',
  /** Complete */
  Complete = 'COMPLETE',
  /** Nextstep */
  Nextstep = 'NEXTSTEP',
  /** Requirements */
  Requirements = 'REQUIREMENTS',
  /** View */
  View = 'VIEW',
  /** Worklet */
  Worklet = 'WORKLET'
}

export type OntologyType = {
  __typename?: 'OntologyType';
  classes: Maybe<Array<Maybe<ClassType>>>;
  created: Scalars['DateTime'];
  deleted: Maybe<Scalars['DateTime']>;
  domain: Scalars['String'];
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  org: ExistingOrganizationToJoinType;
};

/**
 * An OperatorWorkflowType is necessary for the Operator view of BaseTen. We want to provide
 * the exact attributes necessary for the operator's applications page and want to expose nothing
 * more to the Operator role.
 */
export type OperatorWorkflowType = {
  __typename?: 'OperatorWorkflowType';
  builder_version: Maybe<Scalars['Int']>;
  created: Scalars['DateTime'];
  description: Scalars['String'];
  id: Maybe<Scalars['String']>;
  last_deployed_to_production_at: Maybe<Scalars['DateTime']>;
  modified: Scalars['DateTime'];
  name: Maybe<Scalars['String']>;
  shared_states: Maybe<Array<Maybe<Scalars['String']>>>;
  starting_view_id: Maybe<Scalars['String']>;
  tags: Maybe<Scalars['GenericScalar']>;
  views: Maybe<Array<Maybe<PubliclyVisibleViewType>>>;
};

export type OracleMigrationInfoRequestType = {
  new_deployment_spec_id: Scalars['String'];
  old_deployment_spec_id: Scalars['String'];
  oracle_id: Scalars['String'];
  version_ids: Array<InputMaybe<Scalars['String']>>;
};

export type OracleMigrationInfoType = {
  __typename?: 'OracleMigrationInfoType';
  migrating_version_infos: Maybe<Array<Maybe<OracleVersionMigrationInfoType>>>;
  new_deployment_spec: Maybe<DeploymentSpecType>;
  old_deployment_spec: Maybe<DeploymentSpecType>;
  oracle: Maybe<ModelType>;
  organization_id: Maybe<Scalars['String']>;
  organization_name: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum OracleProductionReleaseStatus {
  /** CANCELED */
  Canceled = 'CANCELED',
  /** FAILED */
  Failed = 'FAILED',
  /** RAMPING_DOWN */
  RampingDown = 'RAMPING_DOWN',
  /** RAMPING_UP */
  RampingUp = 'RAMPING_UP',
  /** RELEASING */
  Releasing = 'RELEASING',
  /** SUCCEEDED */
  Succeeded = 'SUCCEEDED'
}

export type OracleStableEnvironmentType = {
  __typename?: 'OracleStableEnvironmentType';
  created: Scalars['DateTime'];
  current_oracle_version: Maybe<ModelVersionType>;
  id: Scalars['ID'];
  idle_time_seconds: Scalars['Int'];
  max_replica: Scalars['Int'];
  min_replica: Scalars['Int'];
  modified: Scalars['DateTime'];
  name: Scalars['String'];
  parallelism: Scalars['Int'];
  ramp_up_duration_seconds: Maybe<Scalars['Int']>;
  ramp_up_step_size: Maybe<Scalars['Int']>;
  scaling_window_seconds: Scalars['Int'];
};

export type OracleVersionMigrationInfoType = {
  __typename?: 'OracleVersionMigrationInfoType';
  is_done: Maybe<Scalars['Boolean']>;
  new_deployment_replicas: Maybe<Scalars['Int']>;
  old_deployment_replicas: Maybe<Scalars['Int']>;
  oracle_version: Maybe<ModelVersionType>;
};

/** An enumeration. */
export enum OrganizationGitProviderIntegrationInstallationType {
  /** Organization */
  Organization = 'ORGANIZATION',
  /** User */
  User = 'USER'
}

/** An enumeration. */
export enum OrganizationGitProviderIntegrationStatus {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** ERROR */
  Error = 'ERROR',
  /** PENDING */
  Pending = 'PENDING',
  /** UPDATE_PERMISSIONS */
  UpdatePermissions = 'UPDATE_PERMISSIONS'
}

/** An enumeration. */
export enum OrganizationJoinExistingSetting {
  /** DISABLED */
  Disabled = 'DISABLED',
  /** ENABLED */
  Enabled = 'ENABLED',
  /** INELIGIBLE */
  Ineligible = 'INELIGIBLE',
  /** UNKNOWN */
  Unknown = 'UNKNOWN'
}

export type OrganizationType = {
  __typename?: 'OrganizationType';
  applications_and_data_enabled: Maybe<Scalars['Boolean']>;
  billing_email: Scalars['String'];
  /** If set, we'll attempt charge the org when their current period balance reaches this amount. */
  billing_threshold: Maybe<Scalars['Int']>;
  can_update_payment_method: Maybe<Scalars['Boolean']>;
  credits_balance_minutes: Maybe<Scalars['Int']>;
  data_enabled: Maybe<Scalars['Boolean']>;
  /** If join_existing_setting is ENABLED, users with the specified email domain can join the org. */
  domain: Maybe<Scalars['String']>;
  dual_env_enabled: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  git_sync_enabled: Maybe<Scalars['Boolean']>;
  integrations: Maybe<Array<Maybe<IntegrationType>>>;
  /** Designates whether an organizations environment has been provisioned. */
  is_provisioned: Scalars['Boolean'];
  join_existing_org_enabled: Maybe<Scalars['Boolean']>;
  /** Controls whether users with the org's domain can join the org. */
  join_existing_setting: OrganizationJoinExistingSetting;
  max_replicas_per_oracle_version: Scalars['Int'];
  model_resource_management_enabled: Maybe<Scalars['Boolean']>;
  monetary_credit_balance: Maybe<Scalars['Int']>;
  monetary_credit_granted: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  org_pynode_available: Maybe<Scalars['Boolean']>;
  payment_method_status: Maybe<PaymentMethodStatus>;
  pending_deactivation_date: Maybe<Scalars['DateTime']>;
  scale_new_models_to_zero: Scalars['Boolean'];
  tier_name: Maybe<Levels>;
  total_credits_minutes: Maybe<Scalars['Int']>;
  workspace_name: Maybe<Scalars['String']>;
};

export type PatchDraftTruss = {
  __typename?: 'PatchDraftTruss';
  error: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  needs_full_deploy: Maybe<Scalars['Boolean']>;
  succeeded: Maybe<Scalars['Boolean']>;
  user: Maybe<UserType>;
  version_id: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum PaymentMethodStatus {
  HasPaymentMethod = 'HAS_PAYMENT_METHOD',
  NeedsPaymentMethod = 'NEEDS_PAYMENT_METHOD',
  PaymentMethodFailed = 'PAYMENT_METHOD_FAILED',
  Untracked = 'UNTRACKED'
}

export type PipeType = {
  __typename?: 'PipeType';
  data_source_id: Maybe<Scalars['String']>;
  deployed: Scalars['Boolean'];
  id: Scalars['ID'];
  worklet_id: Maybe<Scalars['String']>;
};

export type PreReleaseData = {
  __typename?: 'PreReleaseData';
  next_release_codefiles: Maybe<Scalars['GenericScalar']>;
  next_release_git_repositories: Maybe<Scalars['GenericScalar']>;
  next_release_pynode_requirements: Maybe<Scalars['GenericScalar']>;
  next_release_semver_major: Maybe<Scalars['String']>;
  next_release_semver_minor: Maybe<Scalars['String']>;
  next_release_semver_patch: Maybe<Scalars['String']>;
  next_release_views: Maybe<Scalars['GenericScalar']>;
  next_release_worklets: Maybe<Scalars['GenericScalar']>;
};

export type PretrainedModelType = {
  __typename?: 'PretrainedModelType';
  avatar_url: Maybe<Scalars['String']>;
  contact_sales: Maybe<Scalars['Boolean']>;
  cover_image_url: Maybe<Scalars['String']>;
  default_instance_type: Maybe<InstanceType>;
  description: Maybe<Scalars['String']>;
  flag: Maybe<Scalars['String']>;
  framework: Maybe<Scalars['String']>;
  github_url: Maybe<Scalars['String']>;
  hf_repo_id: Maybe<Scalars['String']>;
  hf_task: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  needs_hf_token: Maybe<Scalars['Boolean']>;
  pretty_name: Maybe<Scalars['String']>;
  readme: Maybe<Scalars['String']>;
  s3_key: Maybe<Scalars['String']>;
  tags: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** An enumeration. */
export enum ProductCategory {
  ModelServing = 'MODEL_SERVING'
}

export type ProductCategoryUsage = {
  __typename?: 'ProductCategoryUsage';
  category: Maybe<ProductCategory>;
  cost: Maybe<Scalars['Decimal']>;
  inference_requests: Maybe<Scalars['Int']>;
  items: Maybe<Array<Maybe<BillItem>>>;
  minutes: Maybe<Scalars['Int']>;
};

export type PromoteDraftModelVersion = {
  __typename?: 'PromoteDraftModelVersion';
  new_version: Maybe<ModelVersionType>;
  ok: Maybe<Scalars['Boolean']>;
};

export type PromoteOracleVersionToStableEnvironment = {
  __typename?: 'PromoteOracleVersionToStableEnvironment';
  model_version: Maybe<ModelVersionType>;
  stable_environment: Maybe<OracleStableEnvironmentType>;
};

export type PromotionSettingsType = {
  ramp_up_duration_seconds?: InputMaybe<Scalars['Int']>;
  ramp_up_step_size?: InputMaybe<Scalars['Int']>;
};

/** All data exposed by this Type is accessible publicly if the View has been shared by the creator. */
export type PubliclyVisibleViewType = {
  __typename?: 'PubliclyVisibleViewType';
  author: Maybe<UserType>;
  components: Scalars['JSONString'];
  created: Maybe<Scalars['DateTime']>;
  current_version_id: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  layout: Scalars['JSONString'];
  modified: Scalars['DateTime'];
  name: Scalars['String'];
  node_actions: Maybe<Array<Maybe<Scalars['String']>>>;
  node_id: Maybe<Scalars['String']>;
  worklet_id: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum PyNodeRequirementStatus {
  /** FAILED */
  Failed = 'FAILED',
  /** PROCESSING */
  Processing = 'PROCESSING',
  /** SUCCEEDED */
  Succeeded = 'SUCCEEDED'
}

export type PyNodeRequirementType = {
  __typename?: 'PyNodeRequirementType';
  error_message: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  requirements_txt: Maybe<Scalars['String']>;
  status: PyNodeRequirementStatus;
  system_requirements: Maybe<Scalars['String']>;
};

/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPrediction = {
  __typename?: 'QueryPrediction';
  available_billing_periods: Maybe<Array<Maybe<BillingPeriod>>>;
  /** @deprecated Use models instead */
  baseten_models: Maybe<Array<Maybe<ModelType>>>;
  billfred_entity_types: Maybe<Array<Maybe<BillfredEntityType>>>;
  billfred_get_detailed_entity: Maybe<BillfredDetailedEntity>;
  billfred_search_entities: Maybe<Array<Maybe<BillfredEntity>>>;
  chain: Maybe<ChainType>;
  chain_deployment: Maybe<ChainDeploymentType>;
  chain_stable_environment: Maybe<ChainStableEnvironmentType>;
  chain_stable_environments: Maybe<Array<Maybe<ChainStableEnvironmentType>>>;
  chains: Maybe<Array<Maybe<ChainType>>>;
  code_file: Maybe<CodeFileType>;
  codebase: Maybe<CodeBaseType>;
  connection_status: Maybe<Scalars['String']>;
  current_usage_for_chain: Maybe<CurrentModelUsageType>;
  current_usage_for_model: Maybe<CurrentModelUsageType>;
  data_sources: Maybe<Array<Maybe<DataSourceType>>>;
  existing_organization: Maybe<ExistingOrganizationToJoinType>;
  external_connection: Maybe<ExternalConnectionType>;
  external_connections: Maybe<Array<Maybe<ExternalConnectionType>>>;
  feature_flags: Maybe<Array<Maybe<FeatureFlagType>>>;
  /** Generate a what-if for migrating the listed oracles */
  generate_migration_what_if_info: Maybe<MigrationInfoType>;
  get_migration_status: Maybe<MigrationInfoType>;
  git_push: Maybe<GitIntegrationPushType>;
  imported_git_repository: Maybe<ImportedGitRepositoryType>;
  /** Model version metrics evaluated over a single time range (as opposed to multiple values over time) */
  instant_model_metrics: Maybe<InstantModelMetrics>;
  integrations: Maybe<Array<Maybe<IntegrationType>>>;
  invited_user: Maybe<InvitedUserType>;
  invited_users: Maybe<Array<Maybe<InvitedUserType>>>;
  invoices: Maybe<Array<Maybe<StripeInvoiceType>>>;
  join_requested_users: Maybe<Array<Maybe<UserType>>>;
  listed_instances: Maybe<Array<Maybe<InstanceType>>>;
  model: Maybe<ModelType>;
  model_s3_download_url: Maybe<SignedS3DownloadUrlType>;
  model_s3_upload_credentials: Maybe<ModelS3UploadCredentials>;
  model_version: Maybe<ModelVersionType>;
  models: Maybe<Array<Maybe<ModelType>>>;
  node_invocations: Maybe<Array<Maybe<NodeInvocationType>>>;
  onboarding_checklist: Maybe<OnboardingChecklistType>;
  ontology: Maybe<OntologyType>;
  operator_workflows: Maybe<Array<Maybe<OperatorWorkflowType>>>;
  oracle_production_releases_for_environment: Maybe<Array<Maybe<ModelProductionReleaseType>>>;
  oracle_stable_environment: Maybe<OracleStableEnvironmentType>;
  oracle_stable_environments_for_model: Maybe<Array<Maybe<OracleStableEnvironmentType>>>;
  org_api_keys: Maybe<Array<Maybe<UserApiKeyType>>>;
  org_api_keys_by_username: Maybe<Array<Maybe<UserApiKeyType>>>;
  organization: Maybe<OrganizationType>;
  output_data: Maybe<Scalars['String']>;
  pre_release_data: Maybe<PreReleaseData>;
  pretrained_model: Maybe<PretrainedModelType>;
  pretrained_models: Maybe<Array<Maybe<PretrainedModelType>>>;
  queries: Maybe<Array<Maybe<QueryType>>>;
  /** Model version metrics provided as values over time */
  range_model_metrics: Maybe<RangeModelMetrics>;
  release_deployment: Maybe<ReleaseDeploymentType>;
  secret: Maybe<SecretType>;
  secrets: Maybe<Array<Maybe<SecretType>>>;
  signed_s3_upload_url: Maybe<SignedS3UploadUrlType>;
  stripe_payment_method: Maybe<StripePaymentMethodType>;
  test_external_connection: Maybe<Scalars['JSONString']>;
  usage_for_billing_period: Maybe<CurrentBillingPeriodUsage>;
  /** @deprecated Use usage_for_billing_period instead */
  usage_for_current_billing_period: Maybe<CurrentBillingPeriodUsage>;
  user: Maybe<UserType>;
  user_api_keys: Maybe<Array<Maybe<UserApiKeyType>>>;
  user_files_signed_s3_upload_url: Maybe<SignedS3UploadUrlType>;
  users: Maybe<Array<Maybe<UserType>>>;
  view: Maybe<PubliclyVisibleViewType>;
  view_with_env: Maybe<EnvAwarePubliclyVisibleViewType>;
  webhook_signing_secret_text: Maybe<WebhookSigningSecretText>;
  webhook_signing_secrets: Maybe<Array<Maybe<WebhookSigningSecretType>>>;
  workflow: Maybe<WorkflowType>;
  workflows: Maybe<Array<Maybe<WorkflowType>>>;
  worklet_runs: Maybe<Array<Maybe<WorkletRunType>>>;
  worklet_runs_count: Maybe<Scalars['Int']>;
  worklets: Maybe<Array<Maybe<WorkletType>>>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionBillfred_Get_Detailed_EntityArgs = {
  entity_id?: InputMaybe<Scalars['String']>;
  entity_type?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionBillfred_Search_EntitiesArgs = {
  entity_type?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionChainArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionChain_DeploymentArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionChain_Stable_EnvironmentArgs = {
  id: Scalars['String'];
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionChain_Stable_EnvironmentsArgs = {
  chain_id: Scalars['String'];
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionCode_FileArgs = {
  code_file_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionCodebaseArgs = {
  deployment_env?: InputMaybe<DeploymentEnvEnum>;
  release_version?: InputMaybe<Scalars['String']>;
  workflow_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionConnection_StatusArgs = {
  connection_parameters?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionCurrent_Usage_For_ChainArgs = {
  chain_id: Scalars['String'];
  timezone_name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionCurrent_Usage_For_ModelArgs = {
  model_id: Scalars['String'];
  timezone_name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionExternal_ConnectionArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionGenerate_Migration_What_If_InfoArgs = {
  oracle_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionGet_Migration_StatusArgs = {
  request?: InputMaybe<MigrationInfoRequestType>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionGit_PushArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionImported_Git_RepositoryArgs = {
  id: Scalars['String'];
  version_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionInstant_Model_MetricsArgs = {
  end: Scalars['DateTime'];
  model_version_id: Scalars['String'];
  start: Scalars['DateTime'];
  time_divisor_seconds: Scalars['Int'];
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionInvited_UserArgs = {
  email?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionModelArgs = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionModel_S3_Download_UrlArgs = {
  model_version_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionModel_VersionArgs = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionNode_InvocationsArgs = {
  node_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionOracle_Production_Releases_For_EnvironmentArgs = {
  environment_name: Scalars['String'];
  model_id: Scalars['String'];
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionOracle_Stable_EnvironmentArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionOracle_Stable_Environments_For_ModelArgs = {
  model_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionOrg_Api_Keys_By_UsernameArgs = {
  last_used_days?: InputMaybe<Scalars['Int']>;
  username: Scalars['String'];
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionOutput_DataArgs = {
  deployment_env?: InputMaybe<Scalars['String']>;
  workflow_id?: InputMaybe<Scalars['String']>;
  worklet_run_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionPre_Release_DataArgs = {
  workflow_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionPretrained_ModelArgs = {
  name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionRange_Model_MetricsArgs = {
  end: Scalars['DateTime'];
  model_version_id: Scalars['String'];
  start: Scalars['DateTime'];
  step_seconds: Scalars['Int'];
  time_divisor_seconds: Scalars['Int'];
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionRelease_DeploymentArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionSecretArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionSigned_S3_Upload_UrlArgs = {
  model_file_name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionTest_External_ConnectionArgs = {
  connection_params?: InputMaybe<Scalars['JSONString']>;
  connection_type?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionUsage_For_Billing_PeriodArgs = {
  billing_period_index: Scalars['Int'];
  timezone_name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionUser_Files_Signed_S3_Upload_UrlArgs = {
  file_name?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionViewArgs = {
  deployment_env?: InputMaybe<DeploymentEnvEnum>;
  id?: InputMaybe<Scalars['String']>;
  release_version?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionView_With_EnvArgs = {
  deployment_env?: InputMaybe<DeploymentEnvEnum>;
  id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionWebhook_Signing_Secret_TextArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionWorkflowArgs = {
  deployment_env?: InputMaybe<DeploymentEnvEnum>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  release_version?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionWorklet_RunsArgs = {
  deployment_env?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  page_offset?: InputMaybe<Scalars['Int']>;
  workflow_id?: InputMaybe<Scalars['String']>;
  worklet_id?: InputMaybe<Scalars['String']>;
};


/** This class will inherit from multiple Queries. One mutation per query. */
export type QueryPredictionWorklet_Runs_CountArgs = {
  deployment_env?: InputMaybe<Scalars['String']>;
  workflow_id?: InputMaybe<Scalars['String']>;
  worklet_id?: InputMaybe<Scalars['String']>;
};

export type QueryType = {
  __typename?: 'QueryType';
  author: Maybe<UserType>;
  created: Maybe<Scalars['DateTime']>;
  current_version_id: Maybe<Scalars['String']>;
  data_source: Maybe<DataSourceType>;
  id: Maybe<Scalars['String']>;
  modified: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  query_param_names: Maybe<Scalars['GenericScalar']>;
  query_str: Maybe<Scalars['String']>;
};

export type RangeModelMetrics = {
  __typename?: 'RangeModelMetrics';
  /** Number of requests in the async queue */
  async_queue_size: Maybe<Array<Maybe<SingleValueDatapoint>>>;
  /** CPU usage measured in cores, averaged over all model replicas */
  cpu_usage: Maybe<Array<Maybe<SingleValueDatapoint>>>;
  /** Percentiles and average of the response time distribution, including any queueing and routing time */
  e2e_response_time_stats: Maybe<Array<Maybe<ResponseTimeDatapoint>>>;
  /** GPU memory usage measured in bytes, averaged over all model replicas */
  gpu_memory_usage: Maybe<Array<Maybe<SingleValueDatapoint>>>;
  /** Fractional GPU utilization, averaged over all model replicas. 1 = full utilization, 0 = no utilization */
  gpu_usage: Maybe<Array<Maybe<SingleValueDatapoint>>>;
  /** Number of inference requests per unit time */
  inference_volume: Maybe<Array<Maybe<SingleValueDatapoint>>>;
  /** Number of inference requests per unit time broken down by HTTP status code range */
  inference_volume_by_status: Maybe<Array<Maybe<InferenceVolumeByStatusDatapoint>>>;
  /** Memory usage measured in bytes, averaged over all model replicas */
  memory_usage: Maybe<Array<Maybe<SingleValueDatapoint>>>;
  /** Number of replicas in a starting up state */
  replica_count_by_status: Maybe<Array<Maybe<ReplicaByStatusDatapoint>>>;
  /** Percentiles and average of the response time distribution */
  response_time_stats: Maybe<Array<Maybe<ResponseTimeDatapoint>>>;
  /** Percentiles of the time spent in the async queue */
  time_in_async_queue: Maybe<Array<Maybe<ResponseTimeDatapoint>>>;
};

export type RelatedEntityList = {
  __typename?: 'RelatedEntityList';
  entities: Maybe<Array<Maybe<BillfredEntity>>>;
  entity_type: Maybe<BillfredEntityType>;
  name: Maybe<Scalars['String']>;
};

export type RelatedEntitySingleton = {
  __typename?: 'RelatedEntitySingleton';
  entity: Maybe<BillfredEntity>;
  entity_type: Maybe<BillfredEntityType>;
  name: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum ReleaseDeploymentStatus {
  /** DEPLOYING */
  Deploying = 'DEPLOYING',
  /** FAILED */
  Failed = 'FAILED',
  /** SUCCEEDED */
  Succeeded = 'SUCCEEDED'
}

export type ReleaseDeploymentType = {
  __typename?: 'ReleaseDeploymentType';
  error_message: Maybe<Scalars['String']>;
  git_push: Maybe<GitIntegrationPushType>;
  id: Scalars['ID'];
  status: ReleaseDeploymentStatus;
};

export type ReleaseType = {
  __typename?: 'ReleaseType';
  created: Scalars['DateTime'];
  creator: Maybe<UserType>;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type RemoveInvitedUser = {
  __typename?: 'RemoveInvitedUser';
  ok: Maybe<Scalars['Boolean']>;
};

export type RemoveUserRole = {
  __typename?: 'RemoveUserRole';
  ok: Maybe<Scalars['Boolean']>;
};

export type ReplicaByStatusDatapoint = {
  __typename?: 'ReplicaByStatusDatapoint';
  active: Scalars['Int'];
  starting: Scalars['Int'];
  timestamp: Scalars['DateTime'];
};

export type RequestToJoinOrganization = {
  __typename?: 'RequestToJoinOrganization';
  admin_name: Maybe<Scalars['String']>;
  ok: Maybe<Scalars['Boolean']>;
};

export type ResponseTimeDatapoint = {
  __typename?: 'ResponseTimeDatapoint';
  avg: Maybe<Scalars['Float']>;
  p50: Scalars['Float'];
  p90: Scalars['Float'];
  p95: Scalars['Float'];
  p99: Scalars['Float'];
  timestamp: Scalars['DateTime'];
};

export type RetryModelDeployment = {
  __typename?: 'RetryModelDeployment';
  model_version: Maybe<ModelVersionType>;
  retried: Maybe<Scalars['Boolean']>;
};

export type RevokeUserApiKey = {
  __typename?: 'RevokeUserAPIKey';
  id: Maybe<Scalars['String']>;
};

export type RotateWebhookSigningSecret = {
  __typename?: 'RotateWebhookSigningSecret';
  webhook_signing_secret: Maybe<WebhookSigningSecretType>;
  webhook_signing_secret_text: Maybe<Scalars['String']>;
};

export type RouteType = {
  __typename?: 'RouteType';
  allowed_domains: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Maybe<Scalars['String']>;
  is_public: Scalars['Boolean'];
  name: Maybe<Scalars['String']>;
};

export type RunModelStatusCheck = {
  __typename?: 'RunModelStatusCheck';
  deployment_status: Maybe<ModelDeploymentStatusType>;
  id: Maybe<Scalars['String']>;
  /** @deprecated Use deployment_status instead */
  status: Maybe<Scalars['String']>;
};

export type RunOracleMigrationTool = {
  __typename?: 'RunOracleMigrationTool';
  migration_info: Maybe<MigrationInfoType>;
};

export type S3UploadFormFieldsType = {
  __typename?: 'S3UploadFormFieldsType';
  aws_access_key_id: Maybe<Scalars['String']>;
  key: Maybe<Scalars['String']>;
  policy: Maybe<Scalars['String']>;
  signature: Maybe<Scalars['String']>;
};

export type SecretType = {
  __typename?: 'SecretType';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  name: Scalars['String'];
};

export type SetStripeBillingEmail = {
  __typename?: 'SetStripeBillingEmail';
  ok: Maybe<Scalars['Boolean']>;
};

export type SetStripeDefaultPaymentMethod = {
  __typename?: 'SetStripeDefaultPaymentMethod';
  all_invoices_paid: Maybe<Scalars['Boolean']>;
  payment_attempt_error_message: Maybe<Scalars['String']>;
  payment_method: Maybe<StripePaymentMethodType>;
};

export type SetWorkflowPubliclyShared = {
  __typename?: 'SetWorkflowPubliclyShared';
  is_publicly_shared: Maybe<Scalars['Boolean']>;
};

export type SignedS3DownloadUrlType = {
  __typename?: 'SignedS3DownloadUrlType';
  url: Maybe<Scalars['String']>;
};

export type SignedS3UploadUrlType = {
  __typename?: 'SignedS3UploadUrlType';
  form_fields: Maybe<S3UploadFormFieldsType>;
  get_url: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type SingleValueDatapoint = {
  __typename?: 'SingleValueDatapoint';
  timestamp: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type StripeInvoiceType = {
  __typename?: 'StripeInvoiceType';
  amount: Maybe<Scalars['Float']>;
  created: Maybe<Scalars['DateTime']>;
  pdf_link: Maybe<Scalars['String']>;
  period_end: Maybe<Scalars['DateTime']>;
  period_start: Maybe<Scalars['DateTime']>;
  status: Maybe<InvoiceStatus>;
};

/** The types of Stripe PaymentMethods we support */
export enum StripePaymentMethod {
  Card = 'CARD',
  UsBankAccount = 'US_BANK_ACCOUNT'
}

export type StripePaymentMethodType = {
  __typename?: 'StripePaymentMethodType';
  brand: Maybe<Scalars['String']>;
  exp_month: Maybe<Scalars['Int']>;
  exp_year: Maybe<Scalars['Int']>;
  last4: Maybe<Scalars['String']>;
  payment_method: Maybe<Scalars['JSONString']>;
  payment_method_subtitle: Maybe<Scalars['String']>;
  payment_method_title: Maybe<Scalars['String']>;
  payment_method_type: Maybe<StripePaymentMethod>;
};

export type UdmClassBatchMutation = {
  __typename?: 'UdmClassBatchMutation';
  klass: Maybe<ClassType>;
};

export type UpdateChainDeployment = {
  __typename?: 'UpdateChainDeployment';
  chain_deployment: Maybe<ChainDeploymentType>;
  ok: Maybe<Scalars['Boolean']>;
  primary_chain_deployment: Maybe<ChainDeploymentType>;
};

export type UpdateChainletEnvironmentSettings = {
  __typename?: 'UpdateChainletEnvironmentSettings';
  ok: Maybe<Scalars['Boolean']>;
};

export type UpdateImportedGitRepository = {
  __typename?: 'UpdateImportedGitRepository';
  imported_git_repository: Maybe<ImportedGitRepositoryType>;
};

export type UpdateInvitedUserRole = {
  __typename?: 'UpdateInvitedUserRole';
  email: Maybe<Scalars['String']>;
  role_name: Maybe<Scalars['String']>;
};

export type UpdateModelResources = {
  __typename?: 'UpdateModelResources';
  current_deployment_spec: Maybe<ModelDeploymentSpecType>;
  ok: Maybe<Scalars['Boolean']>;
};

export type UpdateModelSettings = {
  __typename?: 'UpdateModelSettings';
  model: Maybe<ModelType>;
};

export type UpdateModelVersion = {
  __typename?: 'UpdateModelVersion';
  id: Maybe<Scalars['ID']>;
  is_primary: Maybe<Scalars['Boolean']>;
  model: Maybe<ModelType>;
};

export type UpdateModelVersionSettings = {
  __typename?: 'UpdateModelVersionSettings';
  current_deployment: Maybe<ModelDeploymentType>;
  ok: Maybe<Scalars['Boolean']>;
};

export type UpdateOnboardingState = {
  __typename?: 'UpdateOnboardingState';
  ok: Maybe<Scalars['Boolean']>;
};

export type UpdateOrCreateQuery = {
  __typename?: 'UpdateOrCreateQuery';
  query: Maybe<QueryType>;
};

export type UpdateOrganization = {
  __typename?: 'UpdateOrganization';
  organization: Maybe<OrganizationType>;
};

export type UpdateQueryName = {
  __typename?: 'UpdateQueryName';
  query: Maybe<QueryType>;
};

export type UpdateStartingView = {
  __typename?: 'UpdateStartingView';
  id: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  company_name?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  profile_use?: InputMaybe<Scalars['String']>;
};

export type UpdateUserRole = {
  __typename?: 'UpdateUserRole';
  email: Maybe<Scalars['String']>;
  role_name: Maybe<Scalars['String']>;
  username: Maybe<Scalars['String']>;
};

export type UpdateView = {
  __typename?: 'UpdateView';
  components: Maybe<Scalars['GenericScalar']>;
  id: Maybe<Scalars['String']>;
  layout: Maybe<Scalars['GenericScalar']>;
  name: Maybe<Scalars['String']>;
};

export type UpdateViewName = {
  __typename?: 'UpdateViewName';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

export type UpdateWalkthroughState = {
  __typename?: 'UpdateWalkthroughState';
  ok: Maybe<Scalars['Boolean']>;
};

export type UpdateWorkflow = {
  __typename?: 'UpdateWorkflow';
  id: Maybe<Scalars['String']>;
};

export type UsagePerDay = {
  __typename?: 'UsagePerDay';
  cost: Maybe<Scalars['Decimal']>;
  date: Maybe<Scalars['Date']>;
  inference_requests: Maybe<Scalars['Int']>;
  minutes: Maybe<Scalars['Int']>;
};

export type UserApiKeyType = {
  __typename?: 'UserAPIKeyType';
  allowed_oracles: Array<ModelType>;
  api_key_type: Maybe<ApiKeyType>;
  created: Scalars['DateTime'];
  id: Maybe<Scalars['String']>;
  last_used: Maybe<Scalars['DateTime']>;
  /** A free-form name for the API key. Need not be unique. 50 characters max. */
  name: Scalars['String'];
  prefix: Scalars['String'];
  /** If the API key is revoked, clients cannot use it anymore. (This cannot be undone.) */
  revoked: Scalars['Boolean'];
};

export type UserType = {
  __typename?: 'UserType';
  applications_and_data_enabled: Maybe<Scalars['Boolean']>;
  company_name: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  email: Maybe<Scalars['String']>;
  first_name: Maybe<Scalars['String']>;
  has_completed_profile: Scalars['Boolean'];
  id: Scalars['ID'];
  last_name: Maybe<Scalars['String']>;
  linkedin_url: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  profile_use: Maybe<Scalars['String']>;
  role_name: Maybe<Scalars['String']>;
  status: Maybe<ApprovalStatus>;
  username: Scalars['String'];
  workspace_name: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum WalkthroughState {
  Codefile = 'CODEFILE',
  Complete = 'COMPLETE',
  Nextstep = 'NEXTSTEP',
  Requirements = 'REQUIREMENTS',
  View = 'VIEW',
  Worklet = 'WORKLET'
}

export type WebhookSigningSecretText = {
  __typename?: 'WebhookSigningSecretText';
  webhook_signing_secret_text: Maybe<Scalars['String']>;
};

export type WebhookSigningSecretType = {
  __typename?: 'WebhookSigningSecretType';
  created: Scalars['DateTime'];
  expires_at: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
};

export type WorkflowType = {
  __typename?: 'WorkflowType';
  artificial_versions: Maybe<Scalars['GenericScalar']>;
  builder_version: Maybe<Scalars['Int']>;
  code_files: Maybe<Array<Maybe<CodeFileType>>>;
  codebase: Maybe<CodeBaseType>;
  codebase_version: Maybe<CodeBaseType>;
  created: Maybe<Scalars['DateTime']>;
  creators: Maybe<Array<Maybe<UserType>>>;
  deleted: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  external_connections: Maybe<Array<Maybe<ExternalConnectionType>>>;
  git_repository_url: Maybe<Scalars['String']>;
  has_undeployed_changes: Maybe<Scalars['Boolean']>;
  id: Maybe<Scalars['String']>;
  imported_git_repositories: Maybe<Array<Maybe<ImportedGitRepositoryType>>>;
  imported_git_repository_versions: Array<ImportedGitRepositoryType>;
  is_publicly_shared: Maybe<Scalars['Boolean']>;
  is_starter_app: Maybe<Scalars['Boolean']>;
  is_synced_to_git: Maybe<Scalars['Boolean']>;
  last_deployed_to_production_at: Maybe<Scalars['DateTime']>;
  latest_pynode_requirement: Maybe<PyNodeRequirementType>;
  modified: Maybe<Scalars['DateTime']>;
  name: Maybe<Scalars['String']>;
  operators: Maybe<Array<Maybe<UserType>>>;
  pipes: Maybe<Array<Maybe<PipeType>>>;
  query_versions: Array<QueryType>;
  releases: Array<ReleaseType>;
  route_versions: Array<RouteType>;
  routes: Maybe<Array<Maybe<RouteType>>>;
  shared_states: Maybe<Array<Maybe<Scalars['String']>>>;
  snapshot_at: Maybe<Scalars['DateTime']>;
  starting_view_id: Maybe<Scalars['String']>;
  tags: Maybe<Scalars['GenericScalar']>;
  view_versions: Array<PubliclyVisibleViewType>;
  views: Maybe<Array<Maybe<PubliclyVisibleViewType>>>;
  worklet_runs: Array<WorkletRunType>;
  worklet_versions: Array<WorkletType>;
  worklets: Maybe<Array<Maybe<WorkletType>>>;
};

export type WorkletMutation = {
  __typename?: 'WorkletMutation';
  worklet: Maybe<WorkletType>;
};

/** An enumeration. */
export enum WorkletRunDeploymentEnv {
  /** draft */
  Draft = 'DRAFT',
  /** production */
  Production = 'PRODUCTION'
}

export type WorkletRunType = {
  __typename?: 'WorkletRunType';
  created: Scalars['DateTime'];
  deployment_env: WorkletRunDeploymentEnv;
  env: Maybe<Scalars['String']>;
  error_message: Maybe<Scalars['String']>;
  execution_state: Scalars['JSONString'];
  id: Scalars['ID'];
  input_data: Maybe<Scalars['String']>;
  latency_ms: Scalars['Int'];
  node_invocations: Maybe<Array<Maybe<NodeInvocationType>>>;
  scheduled: Maybe<Scalars['Boolean']>;
  stack_trace: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  workflow_id: Maybe<Scalars['String']>;
  workflow_name: Maybe<Scalars['String']>;
  worklet_id: Maybe<Scalars['String']>;
  worklet_name: Maybe<Scalars['String']>;
};

export type WorkletScheduleType = {
  __typename?: 'WorkletScheduleType';
  cron: Maybe<CeleryCrontabScheduleType>;
  display_timezone: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  task: Maybe<CeleryPeriodicTask>;
};

export type WorkletType = {
  __typename?: 'WorkletType';
  allowed_domains: Array<Scalars['String']>;
  author: Maybe<UserType>;
  created: Maybe<Scalars['DateTime']>;
  current_version_id: Maybe<Scalars['String']>;
  execute_asynchronously_by_default: Scalars['Boolean'];
  id: Maybe<Scalars['String']>;
  modified: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  node_graph: Scalars['JSONString'];
  nodes: Maybe<Array<Maybe<NodeType>>>;
  schedule: Maybe<WorkletScheduleType>;
  signature: Maybe<AtomSignature>;
  workflow: Maybe<WorkflowType>;
};
